export default {
  'menu.welcome': 'Welcome',
  'menu.more-blocks': 'More Blocks',
  'menu.home': 'Home',
  'menu.admin': 'Admin',
  'menu.admin.sub-page': 'Sub-Page',
  'menu.login': 'Login',
  'menu.register': 'Register',
  'menu.register.result': 'Register Result',
  'menu.dashboard': 'Dashboard',
  'menu.dashboard.analysis': 'Analysis',
  'menu.dashboard.monitor': 'Monitor',
  'menu.dashboard.workplace': 'Workplace',
  'menu.exception.403': '403',
  'menu.exception.404': '404',
  'menu.exception.500': '500',
  'menu.form': 'Form',
  'menu.form.basic-form': 'Basic Form',
  'menu.form.step-form': 'Step Form',
  'menu.form.step-form.info': 'Step Form(write transfer information)',
  'menu.form.step-form.confirm': 'Step Form(confirm transfer information)',
  'menu.form.step-form.result': 'Step Form(finished)',
  'menu.form.advanced-form': 'Advanced Form',
  'menu.list': 'List',
  'menu.list.table-list': 'Search Table',
  'menu.list.basic-list': 'Basic List',
  'menu.list.card-list': 'Card List',
  'menu.list.search-list': 'Search List',
  'menu.list.search-list.articles': 'Search List(articles)',
  'menu.list.search-list.projects': 'Search List(projects)',
  'menu.list.search-list.applications': 'Search List(applications)',
  'menu.profile': 'Profile',
  'menu.profile.basic': 'Basic Profile',
  'menu.profile.advanced': 'Advanced Profile',
  'menu.result': 'Result',
  'menu.result.success': 'Success',
  'menu.result.fail': 'Fail',
  'menu.exception': 'Exception',
  'menu.exception.not-permission': '403',
  'menu.exception.not-find': '404',
  'menu.exception.server-error': '500',
  'menu.exception.trigger': 'Trigger',
  'menu.account': 'Account',
  'menu.account.center': 'Account Center',
  'menu.account.settings': 'Account Settings',
  'menu.account.trigger': 'Trigger Error',
  'menu.account.logout': 'Logout',
  'menu.editor': 'Graphic Editor',
  'menu.editor.flow': 'Flow Editor',
  'menu.editor.mind': 'Mind Editor',
  'menu.editor.koni': 'Koni Editor',

  'menu.business.ecg': '心电监测',
  'menu.心电监测': '心电监测',
  'menu.business.ecg.users': 'MyPatient',
  'menu.business.ecg.ecg-reports': 'ECG分析报告',
  'menu.business.ecg.ecg-records': '监测记录',
  'menu.business.ecg.bindings': '设备绑定查询',
  'menu.business.ecg.log-list': 'APP日志记录',
  'menu.business.ecg.warnings': '危急预警',
  'menu.business.ecg.weak': '低振幅数据提醒',
  'menu.business.ecg.screen': '智能筛查',
  'menu.business.ecg.screen-user': '消费者筛查',
  'menu.business.ecg.sampling': '采样异常数据',
  'menu.business.ecg.device': '设备管理',
  'menu.business.online': '在线问诊',
  'menu.business.ecg.apply': '申请报告',
  'menu.business.ecg.pacemaker': '起搏器指征查询',
  'menu.business.ecg.dynamic': '动态检查报告',
  'menu.business.ecg.remote': '心电遥测报告',
  'menu.business.ecg.preview': '报告预处理',
  'menu.business.ecg.deep-analysis': '报告深度分析',
  'menu.business.ecg.quick-check': '快速检测记录',
  'menu.business.ecg.follow-up': '消费者筛查回访',
  'menu.business.ecg.monitor-screen':'监测筛查报告',
  'menu.business.ecg.pack-list':'ECG批量下载',
  'menu.在线问诊': '在线问诊',
  'menu.business.online.analytic-orders': '分析订单',
  'menu.business.online.signature': '签名审核',
  'menu.business.online.interpretation-orders': '分析解读',
  'menu.business.online.intelligent-screen': '智能筛查订单',
  'menu.business.statistics': '统计分析',
  'menu.统计分析': '统计分析',
  'menu.business.statistics.user-area': '用户分布图',
  'menu.business.statistics.company-usage': '机构设备统计',
  'menu.business.statistics.user-usage': '机构数据统计',
  'menu.business.statistics.doctor-report-amount': '医生报告统计',
  'menu.business.statistics.deep-analysis-amount': '深度分析统计',
  'menu.settings.system': '系统设置',
  'menu.系统管理': '系统管理',
  'menu.business.settings.system.menu-list': '系统菜单管理',
  'menu.business.settings.system.warning': '运维预警',
  'menu.business.settings.system.operation-log': '操作日志',
  'menu.business.settings.system.network-speed': '测速记录',
};
