/*
 * @Author: zhangzhipeng
 * @Date: 2021-03-01 16:52:36
 * @LastEditors: zhangzhipeng
 * @LastEditTime: 2023-03-02 15:03:10
 * @Description: 文件说明
 */

import { def_zoom_height, get_rate, min_zoom_height } from '@/utils/global/zoom';
import defaultSettings, { DefaultSettings, proSettings } from '@config/defaultSettings';
import { MenuTheme } from 'antd';
import { observable } from 'mobx';
import type { USER } from './user/type';

export type STATE = {
  Mobx: MOBX;
};
export type MOBX = {
  refsEcgChart3Pro_focused: boolean;
  /**
   * @Description: 缩放等级
   * @return {*}
   */
  get_rate: number;
  /*
   * 报告中的测量记录列表
   * */
  ecgList: any[];
  /*
   * 多天报告的日期
   * */
  reportDates: any[];
  login: any;
  user: USER;
  clientHeight: any;
  clientWidth: any;
  middleware: any;
  unReadMessage: any[];
  version: Record<string, unknown>;
  userName: any;
  mergeRecord: any;
  freeMode: number;
  repInfo: any;
  repScatterHide: any;
  doctorConclusion: any;
  /**
   * 上一个路由
   */
  pathname: string;
  /**
   * CD1001  系统业务模块
   * CD1002  系统-业务分类
   * CD102  文档类型
   * CD103  文章类别
   * CD104  部门类型
   * CD105  机构类型
   * CD106  科室
   * CD107  机构星级
   * CD108  医生职称
   * CD109  设备类型
   * CD110  入院设备类型
   * CD111  控制类型
   * CD112  报告模板
   * CD113  报告打印项
   * CD200  心电事件类型
   * CD201  心搏类型
   * CD202  测试类型
   * CD203  用户事件症状
   * CD204  分析类型
   * CD205  咨询订单结束原因
   * CD206  ECG分析报告类型
   * CD207  分析订单结束原因
   * CD208  钱包记录来源
   * CD209  药品类型
   * CD210  报告标签
   * CD211  报告统计模板
   * CD212  用户事件状态
   * CD213  报告类型
   * CD214  用户病史
   * CD215  生活习惯
   * CD216  事件预警-高危
   * CD217  事件预警-中危
   * CD218  事件预警-低危
   * CD219  预警注意事项
   * CD220  智能筛查心率时间范围
   * CD301  健康小知识板块
   * CD302  医生知识板块
   * CD401  APP用户反馈类型
   * CD402  APP用户反馈渠道
   * CD501  销售订单商品类型
   * CD601  亲情号分类
   * KB001  典型数据特征
   * SYS001  系统参数
   * SYS101  ECG日志阶段
   * SYS102  ECG日志分类
   * SYS201  运维预警 处理结果
   */
  codeMap: Record<string, any>;
  /**
   * 轮询未读消息
   */
  postUnReadMessage: any[];
  /**
   * 请求数据时加屏蔽罩
   */
  loading: boolean;
  /**
   * 测试
   */
  num: number;
  /**
   * menus
   */
  menus: any[];
  panes: any[];
  activeKey: string;
  other: any;
  EcgBeatsAndEvents: any;
  EcgPublic: {
    headers: {
      oper: string | undefined;
    };
    moduleType: 'rep' | 'ecg' | undefined;
    keyId: string | undefined;
    isLocalData?: boolean;
    localEcgData?: any;
    name: 'ECG分析报告' | '监测记录' | '亿科吉' | '';
    bussiNo?: string;
  };
  /*
   * 更新事件
   */
  updateEvent: number;
  /*
   * 更新事件成功后，需要刷新当前页面的数据
   * */
  needRefresh: boolean;
  /**
   *按下的键盘键
   *
   * @type {string}
   */
  key: string;
  userNameChanged: number;
  IconFont: string;
  page_size_state: number;
  /**
   * 服务器错误弹窗是否显示
   */
  notificationShow: boolean;
  settings: DefaultSettings & { primaryColorString: string };
  font: Record<number, number>;
  hasToken: string | undefined;
  hideButton: boolean;
  sysUserId: any;
  alwaysShowOverlayChart: boolean;
  unconfirmedScreenEvent: any;
  unconfirmedEventIds: any;
  systemNotice: any;
};
export function getWH() {
  let w = document.body.clientWidth;
  let h = document.body.clientHeight;
  w = ~~(document.body.clientWidth / get_rate());
  h = ~~(document.body.clientHeight / get_rate());
  console.log('高:' + h, '宽:' + w);
  return { w, h };
}
export const def_Mobx: MOBX = {
  refsEcgChart3Pro_focused: false,
  get_rate: get_rate(),
  font: {
    12: 12,
    13: 13,
    14: 14,
    15: 15,
    16: 16,
    17: 17,
    18: 18,
    19: 19,
    20: 20,
    21: 21,
    22: 22,
    23: 23,
    24: 24,
    25: 25,
    26: 26,
    27: 27,
    28: 28,
    29: 29,
    30: 30,
  },
  page_size_state: 10,
  ecgList: [],
  reportDates: [],
  login: '',
  user: {},
  clientHeight: getWH().h,
  clientWidth: getWH().w,
  middleware: {},
  unReadMessage: [],
  version: {},
  pathname: '',
  codeMap: {},
  postUnReadMessage: [],
  loading: false,
  num: 1,
  menus: [],
  panes: [],
  activeKey: '',
  other: {},
  EcgBeatsAndEvents: {},
  EcgPublic: {
    headers: {
      oper: undefined,
    },
    moduleType: undefined,
    keyId: undefined,
    isLocalData: false,
    name: '',
    bussiNo: ''
  },
  updateEvent: 0,
  needRefresh: false,
  key: '',
  userName: null,
  mergeRecord: null,
  freeMode: 0,
  userNameChanged: 0,
  IconFont: '/zxweb/static/font_3150332_cpjiywyfno.js',
  notificationShow: false,
  settings: defaultSettings.proSettings,
  repInfo: null,
  repScatterHide: null,
  doctorConclusion: '',
  hasToken: undefined,
  hideButton: false,
  sysUserId: null,
  alwaysShowOverlayChart: false,
  unconfirmedScreenEvent: [],
  unconfirmedEventIds: [],
  systemNotice: [],
};
export const _Mobx: MOBX = observable(def_Mobx);
export default _Mobx;
