/*
 * @Author: zhangzhipeng
 * @Date: 2021-02-04 15:41:28
 * @LastEditors: zhangzhipeng
 * @LastEditTime: 2022-12-27 12:52:41
 * @Description: 文件说明
 */
export default {
  'menu.welcome': '欢迎',
  // demo
  'menu.demo': 'DEMO',
  'menu.demo.hooks': 'hooks',
  'menu.demo.echarts': 'echarts',
  'menu.demo.g2': 'g2',
  'menu.demo.quanju': '全局数据hooks用法',
  'menu.demo.quanju-class': '全局数据class用法',
  'menu.demo.Mobx': 'Mobx',
  'menu.demo.mobx-class': 'MobxClass用法',
  'menu.demo.table': '表格列表',

  'menu.more-blocks': '更多区块',
  'menu.home': '首页',
  'menu.admin': '管理页',
  'menu.admin.sub-page': '二级管理页',
  'menu.login': '登录',
  'menu.register': '注册',
  'menu.register.result': '注册结果',
  'menu.dashboard': 'Dashboard',
  'menu.dashboard.analysis': '分析页',
  'menu.dashboard.monitor': '监控页',
  'menu.dashboard.workplace': '工作台',
  'menu.exception.403': '403',
  'menu.exception.404': '404',
  'menu.exception.500': '500',
  'menu.form': '表单页',
  'menu.form.basic-form': '基础表单',
  'menu.form.step-form': '分步表单',
  'menu.form.step-form.info': '分步表单（填写转账信息）',
  'menu.form.step-form.confirm': '分步表单（确认转账信息）',
  'menu.form.step-form.result': '分步表单（完成）',
  'menu.form.advanced-form': '高级表单',
  'menu.list': '列表页',
  'menu.list.table-list': '查询表格',

  'menu.list.basic-list': '标准列表',
  'menu.list.card-list': '卡片列表',
  'menu.list.search-list': '搜索列表',
  'menu.list.search-list.articles': '搜索列表（文章）',
  'menu.list.search-list.projects': '搜索列表（项目）',
  'menu.list.search-list.applications': '搜索列表（应用）',
  'menu.profile': '详情页',
  'menu.profile.basic': '基础详情页',
  'menu.profile.advanced': '高级详情页',
  'menu.result': '结果页',
  'menu.result.success': '成功页',
  'menu.result.fail': '失败页',
  'menu.exception': '异常页',
  'menu.exception.not-permission': '403',
  'menu.exception.not-find': '404',
  'menu.exception.server-error': '500',
  'menu.exception.trigger': '触发错误',
  'menu.account': '个人页',
  'menu.account.center': '个人中心',
  'menu.account.settings': '个人设置',
  'menu.account.trigger': '触发报错',
  'menu.account.logout': '退出登录',
  'menu.editor': '图形编辑器',
  'menu.editor.flow': '流程编辑器',
  'menu.editor.mind': '脑图编辑器',
  'menu.editor.koni': '拓扑编辑器',

  'menu.business.ecg': '心电监测',
  'menu.心电监测': '心电监测',
  'menu.business.ecg.users': '我的病人',
  'menu.business.ecg.ecg-reports': 'ECG分析报告',
  'menu.business.ecg.ecg-records': '监测记录',
  'menu.business.ecg.bindings': '设备绑定查询',
  'menu.business.ecg.log-list': 'APP日志记录',
  'menu.business.ecg.warnings': '危急预警',
  'menu.business.ecg.weak': '低振幅数据提醒',
  'menu.business.ecg.screen': '智能筛查(医院)',
  'menu.business.ecg.screen-user': '消费者筛查',
  'menu.business.ecg.sampling': '采样异常数据',
  'menu.business.ecg.device': '设备管理',
  'menu.business.online': '在线问诊',
  'menu.business.ecg.apply': '申请报告',
  'menu.business.ecg.pacemaker': '起搏器指征查询',
  'menu.business.ecg.dynamic': '动态检查报告',
  'menu.business.ecg.remote': '心电遥测报告',
  'menu.business.ecg.preview': '报告预处理',
  'menu.business.ecg.deep-analysis': '报告深度分析',
  'menu.business.ecg.quick-check': '快速检测记录',
  'menu.business.ecg.follow-up': '消费者筛查回访',
  'menu.business.ecg.monitor-screen':'监测筛查报告',
  'menu.business.ecg.pack-list':'ECG批量下载',
  'menu.在线问诊': '在线问诊',
  'menu.business.online.analytic-orders': '分析订单',
  'menu.business.online.signature': '签名审核',
  'menu.business.online.interpretation-orders': '分析解读',
  'menu.business.online.intelligent-screen': '智能筛查订单',
  'menu.business.statistics': '统计分析',
  'menu.统计分析': '统计分析',
  'menu.business.statistics.user-area': '用户分布图',
  'menu.business.statistics.company-usage': '机构设备统计',
  'menu.business.statistics.user-usage': '机构数据统计',
  'menu.business.statistics.doctor-report-amount': '医生报告统计',
  'menu.business.statistics.deep-analysis-amount': '深度分析统计',
  'menu.settings.system': '系统设置',
  'menu.系统管理': '系统管理',
  'menu.business.settings.system.menu-list': '系统菜单管理',
  'menu.business.settings.system.warning': '运维预警',
  'menu.business.settings.system.operation-log': '操作日志',
  'menu.business.settings.system.api-logs': 'APP请求记录',
  'menu.business.settings.system.network-speed': '测速记录',
};
